import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Card, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import dayjs from "dayjs";
import { TableHeadCustom, TableNoData, useTable } from 'src/components/table';
import useLocales from 'src/appHooks/useLocales';
import LogsTableRow from './LogsTableRow';
import useResponsive from 'src/hooks/useResponsive';
import { LogsFilters, LogsData, FetchDataFuncArgs } from 'src/@types/logs';
import { useLocation, useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { scrollBarStyle } from 'src/components/scrollbar/Scrollbar';
import LogsToolbar from './LogsToolbar';
import { ToolbarSearchFilters } from 'src/@types/commons';

const getFromDate = () => {
  let from = new Date();

  from.setDate(from.getDate() - 15);

  return from;
};

export const DEFAULT_FILTERS_VALUE: LogsFilters = {
  from: dayjs(getFromDate()).startOf("day").toISOString(),
  to: dayjs(new Date()).endOf("day").toISOString(),
  eventType: "all",
  pageIndex: 0,
  pageSize: 10
};

const BASIC_FILTER_OPTIONS = {
  pageIndex: 0,
  pageSize: 10,
  from: DEFAULT_FILTERS_VALUE.from,
  to: DEFAULT_FILTERS_VALUE.to
};

type LogsProps = {
  labels: ToolbarSearchFilters[];
  tableData: LogsData;
  id: string;
  fetchData: (options: FetchDataFuncArgs) => void;
  isLoading: boolean;
  header: string;
  subheader: string;
  localesPath?: string;
  hideTitle?: boolean;
};

export default function Logs({ labels, tableData, id, fetchData, isLoading, header, subheader, localesPath, hideTitle }: LogsProps) {

  const navigate = useNavigate();

  const { translate, currentLang } = useLocales();

  const isDesktop = useResponsive('up', 'sm');

  const methods = useForm<LogsFilters>({
    defaultValues: DEFAULT_FILTERS_VALUE
  });

  const { page, setPage, order, orderBy, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 10,
  });

  const TABLE_HEAD = useMemo(
    () => [
      { id: 'date', label: `${translate('commons.date')}`, align: 'center' },
      { id: 'time', label: `${translate('commons.time')}`, align: 'center' },
      { id: 'loggedUser', label: `${translate(`user.logs.loggedUser`)}`, align: 'center' },
      { id: 'description', label: `${translate('commons.description')}`, align: 'left' },
    ],
    [translate]
  );

  const { getValues, reset } = methods;

  const location = useLocation();

  const [filters, setFilters] = useState<LogsFilters>(getValues());

  const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

  const logsAdapter = (items: LogsData['items']) => {
    return items?.map((item) => {
      const result = {
        date: dayjs(new Date(item?.eventDate)).format("DD/MM/YYYY"),
        time: dayjs(new Date(item?.eventDate)).format("HH:mm A"),
        loggedUser: '',
        description: `${translate(`${localesPath}.logs.eventTypes.${item?.eventType.charAt(0).toLowerCase() + item?.eventType.slice(1)}`)}`,
      };

      if (item?.loggedUser) {
        result.loggedUser = `${item?.loggedUser?.firstName} ${item?.loggedUser?.lastName}`;
      }

      return result;
    });
  };

  //-------------------------------------------------------------------
  const fetchDataFromFilter = useCallback(async (values: LogsFilters) => {

    var searchFilters: any = {};

    if (values === DEFAULT_FILTERS_VALUE) {
      searchFilters = BASIC_FILTER_OPTIONS;
    } else {

      searchFilters = {
        pageIndex: page,
        pageSize: rowsPerPage,
        eventType: values.eventType !== DEFAULT_FILTERS_VALUE.eventType ? values.eventType : null,
        from: values.from !== DEFAULT_FILTERS_VALUE.from ? values.from : DEFAULT_FILTERS_VALUE.from,
        to: values.to !== DEFAULT_FILTERS_VALUE.to ? values.to : DEFAULT_FILTERS_VALUE.to
      };
    }

    updateFiltersInUrl(searchFilters);
  }, [getValues, order, orderBy, page, rowsPerPage]);

  const removeHash = () => {
    var uri = window.location.toString();

    if (uri.indexOf("#") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("#"));

      window.history.replaceState({}, document.title, clean_uri);
    }
  };

  const updateFiltersInUrl = (filters: any) => {

    const queryString = Object.keys(filters).filter(x => filters[x] && filters[x] !== DEFAULT_FILTERS_VALUE[x])
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`)
      .join('&');

    window.location.hash = queryString;

    if (!queryString) removeHash();
  };

  const getFiltersFromUrl = () => {
    const { hash } = location;

    let searchFilters = {};
    if (hash) {
      const cleanedHash = hash.slice(1);

      const decodedQuery = decodeURIComponent(cleanedHash);

      const searchParams = new URLSearchParams(decodedQuery);

      let filters = {
        pageIndex: page,
        pageSize: rowsPerPage,
        eventType: searchParams.get('eventType'),
        from: searchParams.get('from') || DEFAULT_FILTERS_VALUE.from,
        to: searchParams.get('to') || DEFAULT_FILTERS_VALUE.to
      };

      searchFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== null && value !== 'null'));
    } else {
      searchFilters = {
        pageIndex: page,
        pageSize: rowsPerPage,
        from: DEFAULT_FILTERS_VALUE.from,
        to: DEFAULT_FILTERS_VALUE.to
      };
    }

    return searchFilters as LogsFilters;
  };

  const fetchLogs = (filtersFromUrl: FetchDataFuncArgs) => {

    if (JSON.stringify(filtersFromUrl) !== JSON.stringify(BASIC_FILTER_OPTIONS)) {
      updateFiltersInUrl(filtersFromUrl);
    }

    fetchData({
      ...filtersFromUrl,
      id: id,
      eventType: filtersFromUrl.eventType ? filtersFromUrl.eventType.charAt(0).toUpperCase() + filtersFromUrl.eventType.slice(1) : undefined
    });
  };

  useEffect(() => {

    let searchFilters = getFiltersFromUrl();

    const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

    if (strSearchFilters !== JSON.stringify(filters, Object.keys(filters).sort()) || lastUsedLang !== currentLang.label) {

      setFilters(searchFilters as LogsFilters);
      fetchLogs(searchFilters as FetchDataFuncArgs);
      setLastUsedLang(currentLang.label);
    }

  }, [location, page, rowsPerPage, currentLang]); //eventualmente ID nell'if // old: id, page, order, orderBy, rowsPerPage, filters, currentLang

  //--------------------------------------------------------------------

  const handleToolbarSearch = () => {
    fetchDataFromFilter(getValues());
    setPage(0);
  };

  const handleResetAllFilter = () => {
    setFilters(DEFAULT_FILTERS_VALUE);
    reset(DEFAULT_FILTERS_VALUE);
    setPage(0);
    fetchDataFromFilter(DEFAULT_FILTERS_VALUE as FetchDataFuncArgs);
  };

  const isDefault = (filter: LogsFilters) => {

    if (filter.eventType !== DEFAULT_FILTERS_VALUE.eventType) return false;
    if (filter.from !== DEFAULT_FILTERS_VALUE.from) return false;
    if (filter.to !== DEFAULT_FILTERS_VALUE.to) return false;

    return true;
  };

  const checkBackToButton = () => {
    let check = false;
    let path = "";
    if (localesPath === "vehicle.contractualData") {
      check = true;
      path = "backToContractualData";
    } else if (localesPath === "vehicle.logistic") {
      check = true;
      path = "backToLogisticsData";
    }

    return [check, path];
  };

  return (
    <Card
      sx={{
        p: hideTitle ? 0 : (isDesktop ? 5 : 2),
        pb: 0
      }}
    >
      {!hideTitle &&
        <Box sx={{ mb: 1 }}>
          <Typography variant="h5">
            {header}
          </Typography>

          <Typography variant="body2" sx={{ mt: 1 }}>
            {subheader}
          </Typography>
        </Box>
      }

      <FormProvider {...methods}>
        <LogsToolbar
          filters={filters}
          isDefault={isDefault}
          onFilter={handleToolbarSearch}
          resetAll={handleResetAllFilter}
          optionsFields={labels}
        />
      </FormProvider>

      <Box>
        <TableContainer sx={[{ maxHeight: 500, height: rowsPerPage <= 5 ? 400 : 500 }, scrollBarStyle]}>
          <Table stickyHeader>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        height: 1,
                        textAlign: 'center',
                        p: (theme) => theme.spacing(8, 2),
                      }}
                    >
                      <CircularProgress />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {logsAdapter(tableData.items).map((item, idx) => (
                  <LogsTableRow key={idx} row={item} />
                ))}

                <TableNoData isNotFound={tableData.items.length === 0 && !isLoading} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ position: 'relative' }}>
        <Button
          variant="soft"
          color="inherit"
          size={isDesktop ? 'small' : 'large'}
          onClick={() => {
            if (checkBackToButton()[1] === "backToContractualData") {
              navigate(PATH_DASHBOARD.vehicle.contractDataView(id));
            } else {
              navigate(PATH_DASHBOARD.vehicle.logisticView(id));
            }
          }}
          sx={{ position: `${isDesktop ? 'absolute' : 'relative'}`, top: "30%", zIndex: "999", display: `${checkBackToButton()[0] ? 'inline' : 'none'}` }}
        >
          {`${translate(`vehicle.form.${checkBackToButton()[1]}`)}`}
        </Button>

        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 30]}
          component="div"
          count={tableData.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
        />

      </Box>
    </Card>
  );
}