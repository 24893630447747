import { createSlice } from "@reduxjs/toolkit";
import brandOperations from "./brand-operations";
import { Brand } from "src/@types/brand";
import { DEFAULT_LOGS_DATA, LogsData } from "src/@types/logs";
import { ErrorResponse } from "src/@types/commons";

interface BrandReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isBrandLoading: boolean,
    brand: Brand | null,
    adminBrandList: Brand[] | null,
    publicBrandList: Brand[] | null,
    isBrandFound: boolean,
    brandLogs: LogsData,
    pageIndex: number,
    pageSize: number,
    totalCount: number
};

const initialState: BrandReducer = {
    error: null,
    isLoading: false,
    isBrandLoading: false,
    brand: null,
    adminBrandList: null,
    publicBrandList: null,
    isBrandFound: false,
    brandLogs: DEFAULT_LOGS_DATA,
    pageIndex: 0,
    pageSize: 0,
    totalCount: 0
};

const BrandSlice = createSlice({
    name: 'brand',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(brandOperations.getBrandList.fulfilled, (state, action) => {
                if (action.payload[1]) {
                    state.adminBrandList = action.payload[0] as Brand[];
                } else {
                    state.publicBrandList = action.payload[0] as Brand[];
                }
                state.isLoading = false;
            })
            .addCase(brandOperations.getBrandList.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message as string;
            })
            .addCase(brandOperations.getBrand.fulfilled, (state, action) => {
                state.brand = action.payload;
                state.isBrandLoading = false;
                state.isBrandFound = true;
            })
            .addCase(brandOperations.getBrand.rejected, (state, action) => {
                state.brand = initialState.brand;
                state.isBrandLoading = false;
                state.isBrandFound = false;
                state.error = action.error.message as string;
            })
            .addCase(brandOperations.createBrand.fulfilled, (state, action) => {
                state.isBrandLoading = false;
            })
            .addCase(brandOperations.createBrand.rejected, (state, action) => {
                state.isBrandLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(brandOperations.updateBrand.fulfilled, (state, action) => {
                state.isBrandLoading = false;
            })
            .addCase(brandOperations.updateBrand.rejected, (state, action) => {
                state.isBrandLoading = false;
                state.error = action.payload as ErrorResponse;
            })
            .addCase(brandOperations.getBrandLogs.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(brandOperations.getBrandLogs.fulfilled, (state, action) => {
                state.brandLogs = action.payload;
                state.isLoading = false;
            })
            .addCase(brandOperations.getBrandLogs.rejected, (state, action) => {
                state.brandLogs = initialState.brandLogs;
                state.isLoading = false;
                state.error = action.error.message as string;
            });
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startLoadingBrand(state) {
            state.isBrandLoading = true;
        },
        resetBrand(state) {
            state.brand = null;
        }
    }
});

export const {
    startLoading,
    startLoadingBrand,
    resetBrand
} = BrandSlice.actions;

export default BrandSlice.reducer;
