import { Button, Container, MenuItem } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useMemo, useState } from 'react';
import Label, { LabelColor } from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import DateZone from 'src/appComponents/DateZone';
import { DEFAULT_GROUP_FILTERS, EnableDisableGroupSliceProps, GroupEnableStatusType, GroupFilters, GroupSearchResult, GroupStatusesArr, PropertyCategoryArr } from 'src/@types/group';
import { groupOperations } from 'src/redux/group';
import GenericList from 'src/utils/list/GenericList';
import { isEqual } from 'lodash';
import useTabs from 'src/appHooks/useTabs';
import { convertArrayToSelectOptions, FilterListType, QuickFilters } from 'src/@types/list';
import { ToolbarSearchFilters } from 'src/@types/commons';
import { setFiltersInUrl } from 'src/redux/group/group-slices';
import Iconify from 'src/components/iconify';

export default function GroupList() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, groupsList, totalCount, pageSize, pageIndex, filtersInUrl, statistics } = useSelector((state: RootState) => state.group);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('');

    const [filters, setFilters] = useState<GroupFilters>(DEFAULT_GROUP_FILTERS);

    const statsKeysToDelete: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "onlyEnabled"], []);

    const fullKeysToDelete: string[] = useMemo(() => ["all", "from", "to"], []);

    //---- QUICKFILTERS START ----// 
    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'info',
        },
        {
            key: 'enabled',
            label: `${translate('statuses.enabled')}`,
            color: 'success',
        },
        {
            key: 'disabled',
            label: `${translate('statuses.disabled')}`,
            color: 'warning',
        }
    ], [translate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "disabled":
                    return statistics.disabled;
                case "enabled":
                    return statistics.enabled;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- TOOLBAR OPTIONS ----//
    const toolbarFiltersList: ToolbarSearchFilters[] = useMemo(() =>
        [
            { key: 'all', label: `${translate('commons.all')}` },
            { key: 'displayName', label: `${translate('commons.name')}` }
        ], [translate]);

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "displayName",
            label: `${translate(`commons.name`)}`,
            type: "TextField"
        },
        {
            name: "groupType",
            label: `${translate('commons.type')}`,
            type: "Select",
            options: convertArrayToSelectOptions(PropertyCategoryArr, translate, "groups.list.type.")
        },
        {
            name: "status",
            label: `${translate('commons.status')}`,
            type: "Select",
            options: convertArrayToSelectOptions(GroupStatusesArr, translate, "groups.list.status.")
        }
    ], [translate]);

    const updateCheckField = useCallback((field: string, filtersToCheck: GroupFilters) => (filtersToCheck[field] || typeof filtersToCheck[field] === "boolean") && !isEqual(filtersToCheck[field], DEFAULT_GROUP_FILTERS[field]), []);

    const extraSearchFiltersChecks = useCallback((searchFilters: GroupFilters) => {

        switch (filterStatus) {
            case "":
                searchFilters.onlyEnabled = undefined;
                break;
            case "enabled":
                searchFilters.onlyEnabled = true;
                break;
            case "disabled":
                searchFilters.onlyEnabled = false;
                break;
        };

        return searchFilters;
    }, [filterStatus]);

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            navigate(PATH_DASHBOARD.groups.detailsTab(params.row.groupId, 'properties-permissions'));
        }
    };

    const handleEdit = useCallback((id: string) => {
        navigate(PATH_DASHBOARD.groups.edit(id));
    }, [navigate]);

    const handleEnableDisableGroup = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableGroupSliceProps = {
            id: id,
            action: action,
            tab: filterStatus as GroupEnableStatusType,
            totalCount: totalCount,
            pageSize: pageSize,
            listLen: groupsList.length,
            filters: filters
        };

        let x = await dispatch(groupOperations.enableDisableGroup(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, filterStatus, filters, groupsList, pageSize, totalCount, translate]);

    const statuses = useMemo(() => [
        { key: 'Creating', color: 'info' as LabelColor },
        { key: 'Active', color: 'success' as LabelColor },
        { key: 'Updating', color: 'warning' as LabelColor }
    ], []);

    const COLUMNS: GridColDef<GroupSearchResult>[] = useMemo(() => [
        {
            field: 'displayName',
            headerName: `${translate('commons.name')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 1.25 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            sortable: false,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.createdOn)}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'groupType',
            headerName: `${translate('groups.list.columns.groupType')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            sortable: false
        },
        {
            field: 'numberOfOrganizations',
            headerName: `${translate('groups.list.columns.numberOfOrganizations')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        {
            field: 'numberOfUsers',
            headerName: `${translate('groups.list.columns.numberOfUsers')}`,
            flex: isDesktop ? 0.7 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false
        },
        {
            field: 'status',
            headerName: `${translate('commons.status')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={statuses.find((filter) => filter.key === obj.row.status)!.color}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {translate(`groups.list.status.${obj.row.status.charAt(0).toLowerCase() + obj.row.status.slice(1)}`)}
                    </Label>
                );
            }
        },
        {
            field: 'enabled',
            headerName: `${translate('commons.enabled')}`,
            flex: isDesktop ? 1 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj) => {
                return (
                    <Label
                        color={obj.row.enabled ? 'success' : 'error'}
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {obj.row.enabled ? `${translate("commons.enabled")}` : `${translate("commons.disabled")}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisableGroup}
                        handleEdit={handleEdit}
                    />
                );
            }
        }
    ], [translate, isDesktop, statuses, openMenu, actualRow, handleEnableDisableGroup, handleEdit]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.groups.title`)}`}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.groups.title`)}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(`role.type.groups`)}` }
                    ]}
                    action={
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_Create]}>
                            <Button
                                variant="contained"
                                startIcon={<Iconify icon={'eva:plus-fill'} />}
                                onClick={() => navigate(PATH_DASHBOARD.groups.new)}
                                sx={{ borderRadius: "100px" }}
                            >
                                {`${translate('groups.form.new_short')}`}
                            </Button>
                        </PermissionBasedGuard>
                    }
                />

                <GenericList
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    list={groupsList.map((group) => ({ ...group, id: group.groupId }))}
                    isLoading={isLoading}
                    defaultFilters={DEFAULT_GROUP_FILTERS}
                    statsKeysToDelete={statsKeysToDelete}
                    fullKeysToDelete={fullKeysToDelete}
                    quickFilters={quickFilters}
                    renderQuickFilters={renderQuickFilters}
                    toolbarFiltersList={toolbarFiltersList}
                    filtersInSidebar={filtersInSidebar}
                    datagridColumns={COLUMNS}
                    updateCheckField={updateCheckField}
                    extraSearchFiltersChecks={extraSearchFiltersChecks}
                    context={"Group"}
                    setActualRow={setActualRow}
                    handleCellClick={handleCellClick}
                    setFiltersCallback={setFilters}
                    filterStatus={filterStatus}
                    onChangeFilterStatus={onChangeFilterStatus}
                    search={groupOperations.searchGroups}
                    searchStatistics={groupOperations.getStatistics}
                    filtersInUrl={filtersInUrl}
                    setFiltersInUrl={setFiltersInUrl}
                    listDescription={translate('groups.subtitle')}
                    showDates
                />

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: any,
    currentRow: any,
    handleEnableDisable: (id: string, action: "Enable" | "Disable") => Promise<void>,
    handleEdit: (id: string) => void,
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable, handleEdit }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_EnableDisable]}>
                        <MenuItem
                            onClick={() => {
                                if (handleEnableDisable) {
                                    if (object.row.enabled) handleEnableDisable(object.id, "Disable");
                                    else handleEnableDisable(object.id, "Enable");
                                }
                                handleCloseMenu();
                            }}
                            sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                        >
                            {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                        </MenuItem>
                    </PermissionBasedGuard>

                    {object.row.status === "Active" &&
                        <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Group_Edit]}>
                            <MenuItem
                                onClick={() => {
                                    handleEdit(object.id);
                                    handleCloseMenu();
                                }}
                            >
                                {`${translate("commons.edit")}`}
                            </MenuItem>
                        </PermissionBasedGuard>
                    }
                </>
            }
        />
    );
}