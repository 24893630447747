import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ErrorResponse, PagedResponseType } from "src/@types/commons";
import { DEFAULT_GROUP_STATISTICS, EnableDisableGroupSliceProps, GroupSearchResult, GroupStatistics, GroupType } from "src/@types/group";
import groupOperations from "./group-operations";
import { UserSearchResult } from "src/@types/user";
import { OrganizationSearchResult } from "src/@types/organizations";

interface GroupReducer {
    error: ErrorResponse,
    isLoading: boolean,
    isLogsLoading: boolean,
    filtersInUrl: string,
    group: GroupType | null,
    totalCount: number,
    groupsList: GroupSearchResult[],
    groupLogs: PagedResponseType<any>,
    statistics: GroupStatistics,
    pageIndex: number,
    pageSize: number,
    //------Members in detail
    isMembersLoading: boolean,
    membersTotalCount: number,
    membersList: UserSearchResult[],
    membersPageIndex: number,
    membersPageSize: number,
    //------Organizations in detail
    isOrganizationsLoading: boolean,
    organizationsTotalCount: number,
    organizationsList: OrganizationSearchResult[],
    organizationsPageIndex: number,
    organizationsPageSize: number,
    //------Whitelist in detail
    isWhitelistLoading: boolean,
    userWhitelistTotalCount: number,
    userWhitelist: UserSearchResult[] | undefined,
    organizationWhitelistTotalCount: number,
    organizationWhitelist: OrganizationSearchResult[] | undefined,
    //------Blacklist in detail
    isBlacklistLoading: boolean,
    userBlacklistTotalCount: number,
    userBlacklist: UserSearchResult[] | undefined,
    organizationBlacklistTotalCount: number,
    organizationBlacklist: OrganizationSearchResult[] | undefined,
    //------Filter in white or black
    blackUserHasFilter: boolean,
    whiteUserHasFilter: boolean,
    blackOrgHasFilter: boolean,
    whiteOrgHasFilter: boolean
}

const initialState: GroupReducer = {
    error: null,
    isLoading: false,
    isLogsLoading: false,
    filtersInUrl: "",
    group: null,
    totalCount: 0,
    groupsList: [],
    groupLogs: {
        pageIndex: 0,
        pageSize: 0,
        items: [],
        totalCount: 0
    },
    statistics: DEFAULT_GROUP_STATISTICS,
    pageIndex: 0,
    pageSize: 10,
    //------Members in detail
    isMembersLoading: false,
    membersTotalCount: 0,
    membersList: [],
    membersPageIndex: 0,
    membersPageSize: 10,
    //------Organizations in detail
    isOrganizationsLoading: false,
    organizationsTotalCount: 0,
    organizationsList: [],
    organizationsPageIndex: 0,
    organizationsPageSize: 10,
    //------Whitelist in detail
    isWhitelistLoading: false,
    userWhitelistTotalCount: 0,
    userWhitelist: undefined,
    organizationWhitelistTotalCount: 0,
    organizationWhitelist: undefined,
    //------Blacklist in detail
    isBlacklistLoading: false,
    userBlacklistTotalCount: 0,
    userBlacklist: undefined,
    organizationBlacklistTotalCount: 0,
    organizationBlacklist: undefined,
    //------Filter in white or black
    blackUserHasFilter: true,
    whiteUserHasFilter: true,
    blackOrgHasFilter: true,
    whiteOrgHasFilter: true
};

const GroupSlice = createSlice({
    name: 'group',
    initialState,
    extraReducers: (builder) => {
        builder
            //--------------------------------------
            .addCase(groupOperations.createGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.createGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.updateGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.updateGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroup.rejected, (state, action) => {
                state.group = initialState.group;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.getGroup.fulfilled, (state, action) => {
                state.group = action.payload;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroups.rejected, (state, action) => {
                state.groupsList = initialState.groupsList;
                state.error = action.error.message as string;
                state.isLoading = false;
            })
            .addCase(groupOperations.searchGroups.fulfilled, (state, action) => {
                state.groupsList = action.payload.items;
                state.totalCount = action.payload.totalCount;
                state.pageIndex = action.payload.pageIndex;
                state.pageSize = action.payload.pageSize;
                state.isLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.enableDisableGroup.rejected, (state, action) => {
                state.error = action.error.message as string;
            })
            //--------------------------------------
            .addCase(groupOperations.getGroupLogs.rejected, (state, action) => {
                state.isLogsLoading = false;
                state.error = action.error.message as string;
                state.groupLogs = initialState.groupLogs;
            })
            .addCase(groupOperations.getGroupLogs.fulfilled, (state, action) => {
                state.isLogsLoading = false;
                state.groupLogs = action.payload;
            })
            //--------------------------------------
            .addCase(groupOperations.getStatistics.fulfilled, (state, action) => {
                state.statistics = action.payload;
            })
            .addCase(groupOperations.getStatistics.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.statistics = initialState.statistics;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupMembers.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.membersList = initialState.membersList;
                state.isMembersLoading = false;
            })
            .addCase(groupOperations.searchGroupMembers.fulfilled, (state, action) => {
                state.membersList = action.payload.items;
                state.membersTotalCount = action.payload.totalCount;
                state.membersPageIndex = action.payload.pageIndex;
                state.membersPageSize = action.payload.pageSize;
                state.isMembersLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupWhitelistUsers.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.userWhitelist = initialState.userWhitelist;
                state.isWhitelistLoading = false;
            })
            .addCase(groupOperations.searchGroupWhitelistUsers.fulfilled, (state, action) => {
                state.userWhitelist = action.payload.items;
                state.userWhitelistTotalCount = action.payload.totalCount;
                state.isWhitelistLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupBlacklistUsers.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.userBlacklist = initialState.userBlacklist;
                state.isBlacklistLoading = false;
            })
            .addCase(groupOperations.searchGroupBlacklistUsers.fulfilled, (state, action) => {
                state.userBlacklist = action.payload.items;
                state.userBlacklistTotalCount = action.payload.totalCount;
                state.isBlacklistLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupCustomers.rejected, (state, action) => {
                state.organizationsList = initialState.organizationsList;
                state.error = action.error.message as string;
                state.isOrganizationsLoading = false;
            })
            .addCase(groupOperations.searchGroupCustomers.fulfilled, (state, action) => {
                state.organizationsList = action.payload.items;
                state.organizationsTotalCount = action.payload.totalCount;
                state.organizationsPageIndex = action.payload.pageIndex;
                state.organizationsPageSize = action.payload.pageSize;
                state.isOrganizationsLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupOrganizations.rejected, (state, action) => {
                state.organizationsList = initialState.organizationsList;
                state.error = action.error.message as string;
                state.isOrganizationsLoading = false;
            })
            .addCase(groupOperations.searchGroupOrganizations.fulfilled, (state, action) => {
                state.organizationsList = action.payload.items;
                state.organizationsTotalCount = action.payload.totalCount;
                state.organizationsPageIndex = action.payload.pageIndex;
                state.organizationsPageSize = action.payload.pageSize;
                state.isOrganizationsLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupWhitelistOrganizations.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.organizationWhitelist = initialState.organizationWhitelist;
                state.isWhitelistLoading = false;
            })
            .addCase(groupOperations.searchGroupWhitelistOrganizations.fulfilled, (state, action) => {
                state.organizationWhitelist = action.payload.items;
                state.organizationWhitelistTotalCount = action.payload.totalCount;
                state.isWhitelistLoading = false;
            })
            //--------------------------------------
            .addCase(groupOperations.searchGroupBlacklistOrganizations.rejected, (state, action) => {
                state.error = action.error.message as string;
                state.organizationBlacklist = initialState.organizationBlacklist;
                state.isBlacklistLoading = false;
            })
            .addCase(groupOperations.searchGroupBlacklistOrganizations.fulfilled, (state, action) => {
                state.organizationBlacklist = action.payload.items;
                state.organizationBlacklistTotalCount = action.payload.totalCount;
                state.isBlacklistLoading = false;
            })
            ;
    },
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        startMembersLoading(state) {
            state.isMembersLoading = true;
        },
        startOrganizationsLoading(state) {
            state.isOrganizationsLoading = true;
        },
        startLogsLoading(state) {
            state.isLogsLoading = true;
        },
        startWhitelistLoading(state) {
            state.isWhitelistLoading = true;
        },
        startBlacklistLoading(state) {
            state.isBlacklistLoading = true;
        },
        resetGroup(state) {
            state.group = null;
            state.membersList = [];
            state.organizationsList = [];
            state.blackOrgHasFilter = true;
            state.organizationBlacklist = undefined;
            state.blackUserHasFilter = true;
            state.userBlacklist = undefined;
            state.whiteOrgHasFilter = true;
            state.organizationWhitelist = undefined;
            state.whiteUserHasFilter = true;
            state.userWhitelist = undefined;
        },
        setFiltersInUrl(state, action: PayloadAction<string>) {
            state.filtersInUrl = action.payload;
        },
        setBlackUserHasFilter(state, action: PayloadAction<boolean>) {
            state.blackUserHasFilter = action.payload;
        },
        setWhiteUserHasFilter(state, action: PayloadAction<boolean>) {
            state.whiteUserHasFilter = action.payload;
        },
        setBlackOrgHasFilter(state, action: PayloadAction<boolean>) {
            state.blackOrgHasFilter = action.payload;
        },
        setWhiteOrgHasFilter(state, action: PayloadAction<boolean>) {
            state.whiteOrgHasFilter = action.payload;
        },
        //RESET GROUP LIST
        resetPageIndexSize(state) {
            state.pageIndex = 0;
            state.pageSize = 10;
        },
        //DISABLE/ENABLE IN LIST AFTER SERVICE
        enableDisableGroupSL(state, action: PayloadAction<EnableDisableGroupSliceProps>) {

            const { id, tab, newItem, action: payloadAction } = action.payload;

            const index = state.groupsList.findIndex((group) => group.groupId === id);

            state.groupsList[index].enabled = payloadAction === "Enable";

            if (tab !== "") {
                state.groupsList.splice(index, 1);

                state.totalCount -= 1;

                if (newItem) state.groupsList.push(newItem);
                else if (state.groupsList.length === 0 && state.pageIndex > 0) {
                    state.pageIndex -= 1;
                }
            }

            if (payloadAction === "Enable") {
                state.statistics.disabled -= 1;

                state.statistics.enabled += 1;
            } else {
                state.statistics.disabled += 1;

                state.statistics.enabled -= 1;
            }
        }
    }

});

export const {
    startLoading,
    startMembersLoading,
    startLogsLoading,
    resetGroup,
    setFiltersInUrl,
    resetPageIndexSize,
    enableDisableGroupSL,
    startOrganizationsLoading,
    startWhitelistLoading,
    startBlacklistLoading,
    setBlackUserHasFilter,
    setWhiteUserHasFilter,
    setBlackOrgHasFilter,
    setWhiteOrgHasFilter
} = GroupSlice.actions;

export default GroupSlice.reducer;