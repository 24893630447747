import Container from '@mui/material/Container';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import Page from 'src/appComponents/Page';
import useLocales from 'src/appHooks/useLocales';
import { useSettingsContext } from 'src/components/settings';
import { useSelector } from 'react-redux';
import { dispatch, RootState } from 'src/redux/store';
import { FetchDataFuncArgs } from 'src/@types/logs';
import Logs from 'src/appComponents/logs/Logs';
import { organizationsOperations } from 'src/redux/organizations';
import { BRANCH_LOG_LABELS, COMPANY_LOG_LABELS, HOLDING_LOG_LABELS, HUB_LOG_LABELS } from '../../Constants';

interface OrganizationLogsProp {
    id?: string
};

export default function OrganizationLogs(props: OrganizationLogsProp) {

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { logsLoading, logsList, organization } = useSelector((state: RootState) => state.organizations);

    const { id = '' } = { ...useParams(), ...props };

    useEffect(() => {
        if (organization?.id !== id) {
            dispatch(organizationsOperations.getGeneric(id)); //old getParentAndSon
        }
    }, [id, currentLang, organization]);

    const fetchLogs = (searchFilters: FetchDataFuncArgs) => {
        dispatch(organizationsOperations.getOrganizationLogs({ id: id, filters: searchFilters }));
    };

    //-----LABELS

    const createLabels = useCallback((type: String) => {

        let LABELS: string[] = [];

        switch (type) {
            case "branch":
                LABELS = [...BRANCH_LOG_LABELS];
                break;
            case "company":
                LABELS = [...COMPANY_LOG_LABELS];
                break;
            case "holding":
                LABELS = [...HOLDING_LOG_LABELS];
                break;
            case "hub":
                LABELS = [...HUB_LOG_LABELS];
                break;
        }

        return LABELS.map((label) => ({
            label: `${translate(`organization.logs.eventTypes.${label}`)}`,
            key: label,
        }));
    }, [translate]);

    const typesLabels = useMemo(
        () => [
            {
                label: `${translate(`commons.all`)}`,
                key: 'all',
            },
            ...createLabels(organization?.type.toLocaleLowerCase() || ""),
        ],
        [createLabels, translate]
    );

    return (
        <Page title={`${translate('organization.logs.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'} sx={{ p: 0 }}>
                <Logs
                    tableData={logsList as any}
                    isLoading={logsLoading}
                    id={id!}
                    header={`${translate(`commons.logs`)} `}
                    subheader={`${translate(`organization.logs.subtitle`)}`}
                    labels={typesLabels}
                    fetchData={fetchLogs}
                    localesPath={"organization"}
                    hideTitle={true}
                />
            </Container>
        </Page>
    );
}
